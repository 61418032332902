import React, { ReactNode, useEffect, useRef, useState } from "react"

import Helmet from "react-helmet"

import * as styles from "./index.module.scss"

import { Ipage } from "@src/pages"

import { Row, Col } from "antd"
import "antd/dist/antd.css"

//渲染的方法
interface DoubleTitleParamsType {
    title: string;
    second_title: string
}

function DoubleTitle({ title, second_title }: DoubleTitleParamsType) {
    return <div className={styles.double_title}>
        <div className={styles.title}>{title}</div>
        <div className={styles.second_title}>{second_title}</div>
    </div>
}
interface RenderItemParamsType {
    icon: any;
    intro: string;
    details: string;
}

function RenderItem({ icon, intro, details }: RenderItemParamsType) {

    return <div className={styles.render_item}>
        <div className={styles.icon}>
            <img src={icon} alt="icon" />
        </div>
        <div className={styles.intro}>{intro}</div>
        <div className={styles.detail}>{details}</div>
    </div>
}

interface FlexBoxParamsType {
    children: string | ReactNode,
    style?: any,
}

function FlexBox({ children, style }: FlexBoxParamsType) {
    return <div className={styles.flex_box} style={{ ...style }}>{children}</div>
}

interface BannerImgParamsType {
    img: string,
    mobile_img: string
}
function BannerImg({ img, mobile_img }: BannerImgParamsType) {
    return <div className={styles.banner_container} >
        <Row>
            <Col md={24} sm={0} xs={0}><img src={img} alt="this is a image" /></Col>
            <Col md={0} sm={24} xs={24}><img src={mobile_img} alt="this is a image" /></Col>
        </Row>

    </div>
}


//数据获取的方法
function GetIcon(order: string) {
    let res = require(`@images/technology_products/${order}.svg`)
    return res.default
}

function GetBanner(order: string) {
    let res = require(`@images/technology_products/${order}.png`)
    return res.default
}

const QueueOne = [
    {
        icon: "icon1",
        intro: "零基础放心建站",
        details: "10分钟无门槛免费成站 极简流程一步到位"
    },
    {
        icon: "icon2",
        intro: "实践干货秘籍&宝藏资源",
        details: "惠赠全方位经营指南 新手无忧上手"
    }, {
        icon: "icon3",
        intro: "店铺集成管理系统",
        details: "店铺要务轻松管理，动动手指决胜千里；经营数据自动生成，眨眨眼睛一目了然"
    }, {
        icon: "icon4",
        intro: "生态特效插件",
        details: "亮点应用全功能覆盖，无论营销、管理，点击领取定制新天地"
    }, {
        icon: "icon5",
        intro: "全网营销新赛道",
        details: "SEO、FB像素、Google Ads、花式促销、会员系统……超出预想的功能，只为出乎意料的收益"
    },
]
const QueueTwo = [{
    icon: "icon6",
    intro: "多维产品分析工具",
    details: "科学洞察商业先机，智能择优，甄选每一样潜质爆品"
}, {
    icon: "icon7",
    intro: "数据勾勒市场之手",
    details: "精准预见行业发展，透视时势，领跑每一次商务决策"
}]
const QueueThree = [
    {
        icon: "icon8",
        intro: "定向受众无障碍触达",
        details: "自动锁定目标受众，高峰转化、高度留存、高效增长，运营即是运筹帷幄"
    }, {
        icon: "icon9",
        intro: "多维数据立体归纳",
        details: "商业价值通盘整合，集结全网流量，成就您的全面销量"
    },
    {
        icon: "icon10",
        intro: "广告投产性价比飙升",
        details: "动态优化预算，自动化广告启停……不止省钱，还更省心"
    },
]
const QueueFour = [
    {
        icon: "icon11",
        intro: "内容呈现设计速成",
        details: "基于H5的自动化制作平台，轻松点击，让图、文、动画跃然屏上，产品众多优点即刻生动展现"
    }, {
        icon: "icon12",
        intro: "精选创意元素排列组合",
        details: "上万组合创意自动生成，不止10倍效率提升，更是百里挑一的专业输出效果"
    }, {
        icon: "icon13",
        intro: "自学习算法优质进化",
        details: "实现无人值守的创意优化，无惧潮流易变，始终一眼惊艳"
    },
]
const QueueFive = [
    {
        icon: "icon14",
        intro: "分权限集成海量数据",
        details: "深入钻取信息，多维全面分析，跃升经营决策质量"
    }, {
        icon: "icon15",
        intro: "广告资产集中管控",
        details: "账号开通、充值、换绑BM、申诉等多元资源一体整合，运营效率与日倍增"
    }, {
        icon: "icon16",
        intro: "当日ROI实时展示",
        details: "重要数据全面可视，助力发展前瞻，消减不定风险"
    }, {
        icon: "icon17",
        intro: "云ERP系统智能库存管理",
        details: "优质货源、物流对接、仓库管理、智能化网店运营……凡所应有，无所不精"
    },
]
const QueueSix = [
    {
        icon: "icon18",
        intro: "选品风控",
        details: "针对上架商品售价，参考供应商价格，自动预警可疑产品"
    }, {
        icon: "icon19",
        intro: "广告风控",
        details: "系统展示广告素材，违规规则实时更新，人工严格把关审核，从此远离广告违规"
    }, {
        icon: "icon20",
        intro: "侵权风控",
        details: "每日上架自动跟踪，自动匹配品牌商和品牌文字，智能识别存疑侵权"
    },
]
const QueueSeven = [
    {
        icon: "icon21",
        intro: "拔高服务品质",
        details: "对接全球知名物流商，源头保障物流网络稳定通畅，以优惠的价格包揽更高的时效"
    }, {
        icon: "icon22",
        intro: "轨迹智能分析",
        details: "超精度物流轨迹预测分析，买卖双方随时掌握动向信息"
    },
]
const QueueEight = [
    {
        icon: "icon23",
        intro: "争议/拒付处理",
        details: "对接paypal /信用卡通道API，类型事件集中管理；疑议实时回复，从容提升用户体验"
    }, {
        icon: "icon24",
        intro: "客服力量完善",
        details: "打通平台数据，邮件、在线聊天多渠道沟通集成，智能回复，极速响应"
    },
]
export default function TechnologyProducts(params: Ipage) {
    const top = useRef<HTMLDivElement>(null)
    const middle = useRef<HTMLDivElement>(null)
    const bottom = useRef<HTMLDivElement>(null)
    const [scrollLocation, setLocation] = useState('top');
    useEffect(() => {
        if (window.location.hash.substring(1)) {
            setLocation(window.location.hash.substring(1))
        }
        window.onscroll = () => {
            let topScroll = top.current?.getBoundingClientRect().top;
            let middleScroll = middle.current?.getBoundingClientRect().top;
            let bottomScroll = bottom.current?.getBoundingClientRect().top;
            if (topScroll && middleScroll && bottomScroll) {
                if (topScroll < 0 && middleScroll > 0) {
                    setLocation('top')
                    // console.log('top');
                }
                if (middleScroll < 0 && bottomScroll > 0) {
                    setLocation('middle')
                    // console.log("middle");
                }
                if (bottomScroll < 0) {
                    setLocation("bottom")
                    // console.log("bottom");
                }
            }
        }
        return () => {
            window.onscroll = null
        }
    }, [])

    if (typeof window == "object") {
        window.onhashchange = function () {
            // console.log("hashchange>>>>>>>");
            setLocation(window.location.hash.substring(1))
        }
    }

    return <>
        <div className={styles.technology_products}>
            <Helmet title='技术产品-Yunstar' />


            <div className={styles.jump_container}>
                <div className={styles.jump_to}>
                    <a href="#top" className={[scrollLocation == 'top' ? styles.active : '', styles.flex_item].join(' ')} onClick={() => { setLocation('top') }}> SaaS独立站平台</a>
                    <a href="#middle" className={[scrollLocation == 'middle' ? styles.active : '', styles.flex_item].join(' ')} onClick={() => { setLocation('middle') }}> 智能运营工具</a>
                    <a href="#bottom" className={[scrollLocation == 'bottom' ? styles.active : '', styles.flex_item].join(' ')} onClick={() => { setLocation('bottom') }}> 供应链集成</a>
                </div>
            </div>


            {/* SasS 独立平台开始 */}
            {/* 1 */}

            <div>
                <Row>
                    <Col md={0} sm={24} xs={24}>
                        <div className={styles.mobile_title}>
                            SasS 独立站平台
                        </div>
                    </Col>
                </Row>
                <div ref={top} id='top' >
                    <BannerImg img={GetBanner("banner1")} mobile_img={GetBanner("banner1_mobile")} />
                </div>
                <DoubleTitle title="Shoprise，助力中国制造拥抱全球市场" second_title="无经验建站、一键式管理、多维度营销，步步轻松，样样惊喜" />
                <FlexBox>
                    {QueueOne.map(item => <RenderItem icon={GetIcon(item.icon)} intro={item.intro} details={item.details} key={item.details} />)}
                </FlexBox>
                {/* SaaS独立平台结束 */}
                {/* 2 */}
                {/* 智能运营工具开始 */}
                <Row>
                    <Col md={0} sm={24} xs={24}>
                        <div className={styles.mobile_title}>
                            智能运营工具
                        </div>
                    </Col>
                </Row>
                <div ref={middle} id="middle">
                    <BannerImg img={GetBanner('banner2')} mobile_img={GetBanner("banner2_mobile")} />
                </div>
                <DoubleTitle title="用数据的辉光勘破选品迷局" second_title="海量供应链直映产品信息、热门渠道综合潜力爆品、市场用户实时反馈……一手掌握动态活跃数据，让别人眼里的犹疑险阻，成为你一往无前的制胜通途" />
                <FlexBox>
                    {QueueTwo.map(item => <RenderItem icon={GetIcon(item.icon)} intro={item.intro} details={item.details} key={item.details} />)}
                </FlexBox>
                {/* 3 */}
                <BannerImg img={GetBanner('banner3')} mobile_img={GetBanner("banner3_mobile")} />
                <DoubleTitle title="低成本高销量超级助推引擎" second_title="全链路覆盖，全天候触达您的全球潜在买家" />
                <FlexBox>
                    {QueueThree.map(item => <RenderItem icon={GetIcon(item.icon)} intro={item.intro} details={item.details} key={item.details} />)}
                </FlexBox>
                {/* 4 */}
                <BannerImg img={GetBanner('banner4')} mobile_img={GetBanner("banner4_mobile")} />
                <DoubleTitle title="让营销化繁为简，直抵内核" second_title="将重复劳动的时间，留给不会疲惫的高阶算法" />
                <FlexBox>
                    {QueueFour.map(item => <RenderItem icon={GetIcon(item.icon)} intro={item.intro} details={item.details} key={item.details} />)}
                </FlexBox>
                {/* 5 */}
                <BannerImg img={GetBanner('banner5')} mobile_img={GetBanner("banner5_mobile")} />
                <DoubleTitle title="数据即知识，洞见即决策" second_title="强大高效的可视化业务分析，智能敏捷的自助式信息工具" />
                <FlexBox>
                    {QueueFive.map(item => <RenderItem icon={GetIcon(item.icon)} intro={item.intro} details={item.details} key={item.details} />)}
                </FlexBox>
                {/* 6 */}
                <BannerImg img={GetBanner('banner6')} mobile_img={GetBanner("banner6_mobile")} />
                <DoubleTitle title="出海可能遇上的风浪，超前预判抵挡" second_title="源头识别、多元评估、动态控制、预先止损，极限降低风险，保驾业务平安" />
                <FlexBox>
                    {QueueSix.map(item => <RenderItem icon={GetIcon(item.icon)} intro={item.intro} details={item.details} key={item.details} />)}
                </FlexBox>
                {/* 只能选品工具结束 */}
                {/* 7 */}
                {/* 供应链集成开始 */}
                <Row>
                    <Col md={0} sm={24} xs={24}>
                        <div className={styles.mobile_title}>
                            供应链集成
                        </div>
                    </Col>
                </Row>
                <div ref={bottom} id="bottom">
                    <BannerImg img={GetBanner('banner7')} mobile_img={GetBanner("banner7_mobile")} />
                </div>
                <DoubleTitle title="实践远见，供应链系统集成研发" second_title="从点到面，立足产业生态建设开拓" />
                <FlexBox>
                    {QueueSeven.map(item => <RenderItem icon={GetIcon(item.icon)} intro={item.intro} details={item.details} key={item.details} />)}
                </FlexBox>
                {/* 8 */}
                <BannerImg img={GetBanner('banner8')} mobile_img={GetBanner("banner8_mobile")} />
                <DoubleTitle title="用展露关怀的末端环节振响品牌信誉" second_title="让顾客的满意，铸就您更强的跨海竞争力" />
                <FlexBox>
                    {QueueEight.map(item => <RenderItem icon={GetIcon(item.icon)} intro={item.intro} details={item.details} key={item.details} />)}
                </FlexBox>
                {/* 供应链集成结束 */}
            </div>
        </div>
    </>
}